export enum CONFIGURATION_PERMISSION {
    AIRCRAFTRATE = "AIRCRAFTRATE",
    BROKERMGMT = "BROKERMGMT"
}
export enum MENU_PERMISSIONS {
    DASHBOARD = "DASHBOARD",
    CONTACTS = "CONTACTS",
};

export enum SUBMENU_PERMISSIONS {
    DASHBOARD = "DASHBOARD",
    REPORT = "REPORT",
};

export enum GLOBAL_PERMISSIONS {
   VIEW_ROLE_MANAGEMENT = "VIEWROLE",
   VIEW_TEAM_MANAGEMENT = "VIEWTEAM",
   VIEW_USER_MANAGEMENT = "VIEWUSER",
   VIEW_CONTACT_PASSENGER = "VIEWCONTACTPASSENGER",
   TRIP_CREATE= "CREATETRIP",
   VIEWTRIP = "VIEWTRIP",
   VIEW_JET_CARD_AGREEMENT = "VIEWJCAGR",
   VIEW_SALES_DASHBOARD = "SALESDASHBOARD",
   VIEW_FINANCE_DASHBOARD = "FINANCEDASHBOARD",
   VIEW_LEADERSHIP_DASHBOARD = "LEADERSHIPDASHBOARD",
   VIEW_LEADERBOARD_DASHBOARD = "LEADERBOARDDASHBOARD",
   CREATECONTACT = "CREATECONTACT",
   CREATEJCAGR = "CREATEJCAGR",
   JCAGRAIRCRAFTRATE = "JCAGRAIRCRAFTRATE",
   EDIT_OPERATOR_COST = "EDITOPERATORCOST",
   EDITCONTACTNAMETYPE = "EDITCONTACTNAMETYPE",
   UNMARKPURCHASEDLEAD = "UNMARKPURCHASEDLEAD",
   EDITEMAILANDPHONE = "EDITEMAILANDPHONE",
   EDITSIGNIFICANTOTHEREMAILPHONE = "EDITSIGNIFICANTOTHEREMAILPHONE",
   REMOVECONTACTASSIGNMENT = "REMOVECONTACTASSIGNMENT",
   EDITCONTACTIDENTITYDOCUMENT = "EDITCONTACTIDENTITYDOCUMENT"
};

export enum PERMISSIONS {
    DELETE_CONTACT = 'DELETECONTACT',

    VIEW_CONTACT_IDENTITY = 'VIEWCONTACTIDENTITY',
    EDIT_CONTACT_PERSONALINFO = 'EDITCONTACTPERSONALINFO',
    EDIT_CONTACT_CONTACT_INFO = 'EDITCONTACTCONTACTINFO',
    EDIT_SIGNIFICANT_OTHER_INFO = 'EDITSIGNIFICANTOTHERINFO',
    EDIT_CONTACT_IDENTITY = 'EDITCONTACTIDENTITY',
    EDIT_CONTACT_ASSIGNMENT = 'EDITCONTACTASSIGNMENT',

    EDIT_JET_CARD_AGR = 'EDITJCAGR',
    CANCEL_JET_CARD_AGR = 'CANCELJCAGR',

    JC_MANUAL_CREDIT = 'JCMANUALCREDIT',
    JC_MANUAL_DEBIT = 'JCMANUALDEBIT',
    JC_STATEMENT = 'JCSTATEMENT',
    JC_DOWNLOAD_CONTRACT = 'JCDOWNLOADCONTRACT',
    JC_FIRST_PAYMENT = 'JCFIRSTPAYMENT',
    
    JC_AGR_MIN_FUND = 'JCAGRMINFUND',
    JC_AGR_AIRCRAFT_RATE = 'JCAGRAIRCRAFTRATE',
    JC_AGR_MIN_HOUR = 'JCAGRMINHOUR',
    JC_AGR_ESCROW = 'JCAGRESCROW',
    MODIFY_JC_AGR_ESCROW = 'MODIFYJCAGRESCROW',
    JC_DONT_SHOW_FUNDS_TO_CLIENT = 'DONTSHOWFUNDSTOCLIENT',
    JC_AGR_CATERING = 'JCAGRCATERING',
    MODIFY_JC_AGR_CATERING = 'MODIFYJCAGRCATERING',
    BYPASSJCPROMOTION = 'BYPASSJCPROMOTION',

    MODIFY_CONTACT_PASSENGER = 'MODIFYCONTACTPASSENGER',
    EDITPAYMINFO = 'EDITPAYMINFO',
    EDIT_TNC = 'EDITTNC'
};

export enum TRIP_PERMISSONS {
    EDIT_TRIPS = "EDITTRIPS",
    CREATE_DUPLTRIPS = "CREATEDUPLTRIPS",
    CANCEL_TRIP = "CANCELTRIP",
    DELETE_TRIPS = "DELETETRIPS",
    CREATE_TRIP = "CREATETRIP",
    EDIT_TRIP_LEGS = "EDITTRIPLEGS",
    VIEWTRIPNOTES = "VIEWTRIPNOTES",
    MODIIFYTRIPNOTES = "MODIIFYTRIPNOTES",
    CLNTTRIPITINARY = "CLNTTRIPITINARY",
    OPERTRIPITINARY = "OPERTRIPITINARY",
    VIEWTRIPCONTRACT = "VIEWTRIPCONTRACT",
    MANAGEOPCONTRACT = "MANAGEOPCONTRACT",
    VIEWTRIPSCENARIOS = "VIEWTRIPSCENARIOS",
    VIEWTRANSHISTORY = "VIEWTRANSHISTORY",
    VIEWASSOCTRIPS = "VIEWASSOCTRIPS",
    SKPCCAUTH = "SKPCCAUTH",
    EDITCHTNC = "EDITCHTNC",
    ACCJCTRIPPROP = "ACCJCTRIPPROP",
    CANCELTRIP = "CANCELTRIP", 
    VIEWTRIPFEEDBACK = "VIEWACTHISTTRIP"
};

export enum FINANCE_PERMISSION {
    VIEWTRIPFINANCEDETAIL = "VIEWTRIPFINANCEDETAIL",
    EDITLEGTRIPREVIEW = "EDITLEGTRIPREVIEW",
    EDITLEGFINANCEREVIEW = "EDITLEGFINANCEREVIEW",
    EDITREVENUETRIPREVIEW = "EDITREVENUETRIPREVIEW",
    EDITREVENUEFINANCEREVIEW = "EDITREVENUEFINANCEREVIEW",
    EDITHANDLINGTRIPREVIEW = "EDITHANDLINGTRIPREVIEW",
    EDITHANDLINGFINANCEREVIEW = "EDITHANDLINGFINANCEREVIEW",
    EDITINTERNATIONALTRIPREVIEW = "EDITINTERNATIONALTRIPREVIEW",
    EDITINTERNATIONALFINANCEREVIEW = "EDITINTERNATIONALFINANCEREVIEW",
    EDITSPECIALTRIPREVIEW = "EDITSPECIALTRIPREVIEW",
    EDITSPECIALFINANCEREVIEW = "EDITSPECIALFINANCEREVIEW",
    RPTTRIPISSUE = "RPTTRIPISSUE",
    CONFIRMSENDFIN = "CONFIRMSENDFIN",
    SAVEFNLZTRIP = "SAVEFNLZTRIP",
    UNFINALZ = "UNFINALZ",
    PREVFININV = "PREVFININV",
    PREVCLNTINV = "PREVCLNTINV",
    VIEWTRIPFINANCELIST = "VIEWTRIPFINANCELIST",
    VIEWOPERATORFINANCE = "VIEWOPERATORFINANCE",
    EDITOPERATORFINANCE = "EDITOPERATORFINANCE",
    BROKERTOREV = "BROKERTOREV",
};